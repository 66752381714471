








































import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Vue from 'vue';
import FocusLock from 'vue-focus-lock';

import DnbButton from './DnbButton.vue';
import DnbIcon from './DnbIcon.vue';

export default Vue.extend({
  name: 'admin-pop-up',
  components: {
    FocusLock,
    DnbIcon,
    DnbButton,
  },

  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    closable: {
      type: Boolean,
      default: false,
    },
    wide: {
      type: Boolean,
      default: false,
    },
  },

  data(): { show: boolean } {
    return { show: true };
  },

  mounted() {
    disableBodyScroll(this.$refs.modal as HTMLElement);
    document.addEventListener('click', this.onClickOutside);
  },
  beforeDestroy() {
    enableBodyScroll(this.$refs.modal as HTMLElement);
    document.removeEventListener('click', this.onClickOutside);
  },
  methods: {
    onClickOutside(event: any) {
      const { modalContent }: any = this.$refs;
      if (!modalContent.$el.contains(event.target) && this.closable) {
        this.$emit('close-modal');
      }
    },
  },
});
